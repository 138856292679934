import React, { useEffect, useState, useRef } from 'react';
import './LandingPage.css';
import ReactGA from 'react-ga4';

function LandingPage() {
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [typingIndicator, setTypingIndicator] = useState('Typing...');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [emailError, setEmailError] = useState('');
  const chatBoxRef = useRef(null);

  ReactGA.send({
    hitType: 'pageview',
    page: "/",
    title: "landing"
  });

  const messages = [
    { type: 'question', text: "Alex, the CEO of EmbeddingStudio: What's \"Let's Collect\" all about? 🤔" },
    { type: 'answer', text: "Imagine you're a researcher or a project manager, constantly hitting roadblocks trying to find the perfect dataset. 😩" },
    { type: 'answer', text: "\"Let's Collect\" is here to save the day! 🦸‍♂️🦸‍♀️ It's a platform that lets you crowdfund, collaborate, and share datasets." },
    { type: 'answer', text: "Complete with professional annotation services. 💪" },
    { type: 'answer', text: "It's like Kickstarter for datasets!" },
    { type: 'question', text: "Karl, Researcher at Stanford University: Why should I care about \"Let's Collect\"? 🌟" },
    { type: 'answer', text: "Here's the deal: we empower you to create, fund, and share custom datasets through teamwork." },
    { type: 'answer', text: "High-quality annotated data becomes accessible and affordable, letting you focus on your groundbreaking research! 🔬💡" },
    { type: 'question', text: "Alex, the CEO of EmbeddingStudio: What problems does \"Let's Collect\" solve? 😣" },
    { type: 'answer', text: "We know the pain points. 😓 Researchers and small projects often struggle with:" },
    { type: 'answer', text: "1. High costs of data collection and annotation 💸" },
    { type: 'answer', text: "2. Limited access to specialized datasets 🔒" },
    { type: 'answer', text: "3. Difficulty securing funds for data-related expenses 💰" },
    { type: 'answer', text: "\"Let's Collect\" addresses these by offering a collaborative funding model." },
    { type: 'answer', text: "Connecting you with professional annotation services, and fostering a data-sharing community. 🙌" },
    { type: 'question', text: "Sara, Data Scientist at MIT: How does \"Let's Collect\" work? ⚙️" },
    { type: 'answer', text: "It's as simple as 1-2-3! 🎉" },
    { type: 'answer', text: "1. Create a project and set your funding goal 📊" },
    { type: 'answer', text: "2. Rally the community to back your project 📣" },
    { type: 'answer', text: "3. Once funded, collect your data and let the professional annotation magic happen! ✨" },
    { type: 'answer', text: "Then, share the dataset with your backers and the wider community. 🌍 It's a win-win for everyone! 🥇" },
    { type: 'question', text: "Karl, Researcher at Stanford University: What makes \"Let's Collect\" unique? 🏆" },
    { type: 'answer', text: "Two words: community and collaboration. 🤝 We're not just another data marketplace; we're a thriving ecosystem." },
    { type: 'answer', text: "Where researchers, backers, and annotation pros come together to make data dreams a reality. 💫" },
    { type: 'answer', text: "Plus, our partnerships with top-notch annotation companies ensure you get the best data quality. 👌" },
  ];

  useEffect(() => {
    let messageIndex = 0;

    const interval = setInterval(() => {
      if (messageIndex < messages.length) {
        setTyping(true);
        setTimeout(() => {
          setVisibleMessages(prev => [...prev, messages[messageIndex]]);
          setTyping(false);
          messageIndex += 1;
        }, 2000);
      } else {
        setFinished(true);
        clearInterval(interval);
      }
    }, 4000); // Total time each Q-A pair stays visible (including delays)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scroll({
        top: chatBoxRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [visibleMessages]);

  useEffect(() => {
    if (finished && chatBoxRef.current) {
      chatBoxRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [finished]);

  useEffect(() => {
    if (typing && !finished) {
      const interval = setInterval(() => {
        setTypingIndicator(prev => prev === 'Typing...' ? 'Typing.' : prev === 'Typing.' ? 'Typing..' : 'Typing...');
      }, 500);
      return () => clearInterval(interval);
    }
  }, [typing, finished]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    setEmailError('');
    const webhookUrl = 'https://lets-collect.embeddingstud.io/subscribe'; // URL of your email webhook server
    const payload = { email };

    setSubmitted(true);
    fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="container">
      <div className="left-column">
        <img src="logo.png" alt="Let's Collect Logo" className="logo" />
        <h1>Need some data? Share costs and collaborate on data collections with Let's Collect.</h1>
        <h2>Cut your data annotation costs by at x2 times by teaming up with others. <br/><br/>  Let's Collect is your "Kickstarter" for data annotation projects and a platform for organizing data collection.</h2>
        <form id="subscribe-form" className="subscribe-form" onSubmit={handleSubmit}>
          <h3>Launch in September! Sign up to get early access:</h3>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Get Access</button>
          {emailError && <p className="email-error">{emailError}</p>}
          {submitted && <h3 className="thank-you">Thank you for signing up and Check your email!</h3>}
          {!submitted && <h3 className="thank-you">Become the part of the community</h3>}
        </form>
        <div className="thesis-container">
          <div className="thesis">
            <h3>🤝💸 Collaborate to Fund</h3>
            <p>Share your costs of accessing world best data annotation platforms and services.</p>
          </div>
          <div className="thesis">
            <h3>📂🌍 Collaborate to Collect</h3>
            <p>Streamline your data collection process by organizing your team on our platform.</p>
          </div>
          <div className="thesis">
            <h3>🔍🧑‍💻 Hire Data Collectors</h3>
            <p>Easily find and hire skilled data collectors to gather high-quality data for your projects.</p>
          </div>
        </div>
      </div>
      <div className="right-column">
        <div className={`chat-box ${finished ? 'finished' : ''}`} ref={chatBoxRef}>
          {visibleMessages.map((message, index) => (
            <div key={index} className={`message ${message.type}`}>
              <p>{message.text}</p>
            </div>
          ))}
          {typing && !finished && <div className="typing">{typingIndicator}</div>}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
